(function() {
    'use strict';
    angular
        .module('c3WebApp')
        .factory('Request', Request)
        .factory('RequestXml', RequestXml)
        .factory('RequestPdf', RequestPdf)
        .factory('RequestByBatch', RequestByBatch)
        .factory('RequestCancel', RequestCancel);

    Request.$inject = ['$resource'];
    RequestXml.$inject = ['$resource'];
    RequestPdf.$inject = ['$resource'];
    RequestByBatch.$inject = ['$resource'];
    RequestCancel.$inject = ['$resource'];
    
    function Request ($resource) {
        var resourceUrl =  'api/requests/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }
    
    function RequestByBatch ($resource) {
        var resourceUrl =  'api/requests/batch/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }
    
    function RequestXml ($resource) {
        var resourceUrl =  'api/requests/:id/xml';

        return $resource(resourceUrl, {}, {
            'get': {
                method: 'GET', responseType: 'arraybuffer',
                transformResponse: function(data, headers) {
                    return {
                      data: data,
                      filename: 'certificate.xml'
                    }
                  }
            }
        });
    }
    
    function RequestPdf ($resource) {
        var resourceUrl =  'api/requests/:id/pdf';

        return $resource(resourceUrl, {}, {
            'get': {
                method: 'GET', responseType: 'arraybuffer',
                transformResponse: function(data, headers) {
                    return {
                      data: data,
                      filename: 'certificate.pdf'
                    }
                  }
            }
        });
    }
    
    function RequestCancel ($resource) {
        var resourceUrl =  'api/requests/:id/cancel';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }
})();
