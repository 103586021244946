(function() {
    'use strict';
    angular
        .module('c3WebApp')
        .factory('Template', Template)
        .factory('TemplateXml', TemplateXml)
        .factory('TemplatePdf', TemplatePdf)
        .factory('TemplateJrxml', TemplateJrxml)
        .factory('TemplateRequiredImage', TemplateRequiredImage);

    Template.$inject = ['$resource'];
    TemplateXml.$inject = ['$resource'];
    TemplatePdf.$inject = ['$resource'];
    TemplateJrxml.$inject = ['$resource'];
    TemplateRequiredImage.$inject = ['$resource'];

    function Template ($resource) {
        var resourceUrl =  'api/templates/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }
    
    function TemplateXml ($resource) {
        var resourceUrl =  'api/templates/:id/xml';

        return $resource(resourceUrl, {}, {
            'get': {
                method: 'GET', responseType: 'arraybuffer',
                transformResponse: function(data, headers) {
                    return {
                      data: data,
                      filename: 'template.xml'
                    }
                  }
            }
        });
    }
    
    function TemplatePdf ($resource) {
        var resourceUrl =  'api/templates/:id/pdf';

        return $resource(resourceUrl, {}, {
            'get': {
                method: 'GET', responseType: 'arraybuffer',
                transformResponse: function(data, headers) {
                    return {
                      data: data,
                      filename: 'template.pdf'
                    }
                  }
            }
        });
    }
    
    function TemplateJrxml ($resource) {
        var resourceUrl =  'api/templates/:id/jrxml';

        return $resource(resourceUrl, {}, {
            'get': {
                method: 'GET', responseType: 'arraybuffer',
                transformResponse: function(data, headers) {
                    return {
                      data: data,
                      filename: 'template.jrxml'
                    }
                  }
            }
        });
    }
    
    function TemplateRequiredImage ($resource) {
        var resourceUrl =  'api/templates/required-images';

        return $resource(resourceUrl, {}, {
        	'get': {
                method: 'POST',isArray: true,
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            }
        });
    }
    
})();
