(function() {
    'use strict';

    angular
        .module('c3WebApp')
        .controller('AccessTokenController', AccessTokenController);

    AccessTokenController.$inject = ['$scope', '$state', 'AccessToken', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams'];

    function AccessTokenController($scope, $state, AccessToken, ParseLinks, AlertService, paginationConstants, pagingParams) {

        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.inactivate = inactivate;
        vm.activate = activate;

        loadAll();

        function loadAll () {
            AccessToken.query({
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.accessTokens = data;
                vm.page = pagingParams.page;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }
        
        function activate(accessToken) {
        	accessToken.status = 'ACTIVE';
        	AccessToken.update(accessToken, onSaveSuccess, onSaveError);
        }
        
        function inactivate(accessToken) {
        	accessToken.status = 'INACTIVE';
        	AccessToken.update(accessToken, onSaveSuccess, onSaveError);
        }
        
        function onSaveSuccess (result) {
            $scope.$emit('c3WebApp:accessTokenUpdate', result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }
    }
})();
