(function() {
    'use strict';

    angular
        .module('c3WebApp')
        .controller('TemplateController', TemplateController);

    TemplateController.$inject = ['$state', 'Template', 'ParseLinks', 'AlertService', 'paginationConstants', 
                                  'pagingParams', '$window', 'TemplateXml', 'TemplatePdf', 'TemplateJrxml'];

    function TemplateController($state, Template, ParseLinks, AlertService, paginationConstants, 
    								pagingParams, $window, TemplateXml, TemplatePdf, TemplateJrxml) {

        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;

        loadAll();

        function loadAll () {
            Template.query({
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.templates = data;
                vm.page = pagingParams.page;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }
        
        vm.downloadJrxml = function(template){
        	TemplateJrxml.get({id:template.id}).$promise.then(function(result) {
      	      var url = URL.createObjectURL(new Blob([result.data]));
      	      var a = document.createElement('a');
      	      a.href = url;
      	      a.download = result.filename;
      	      a.target = '_blank';
      	      document.body.appendChild(a);
      	      a.click();
      	      document.body.removeChild(a);
      	    });
        }
        
        vm.downloadPdf = function(template){
        	TemplatePdf.get({id:template.id}).$promise.then(function(result) {
      	      var url = URL.createObjectURL(new Blob([result.data]));
      	      var a = document.createElement('a');
      	      a.href = url;
      	      a.download = result.filename;
      	      a.target = '_blank';
      	      document.body.appendChild(a);
      	      a.click();
      	      document.body.removeChild(a);
      	    });
        }
        
        vm.downloadXml = function(template){
        	TemplateXml.get({id:template.id}).$promise.then(function(result) {
        	      var url = URL.createObjectURL(new Blob([result.data]));
        	      var a = document.createElement('a');
        	      a.href = url;
        	      a.download = result.filename;
        	      a.target = '_blank';
        	      document.body.appendChild(a);
        	      a.click();
        	      document.body.removeChild(a);
        	    });
        }
        
        
        vm.inactivate = function (template) {
        	template.status = 'INACTIVE';
        	Template.update(template);
        }
        
        vm.activate = function (template) {
        	template.status = 'ACTIVE';
        	Template.update(template);
        }
        
    }
})();
