(function() {
    'use strict';

    angular
        .module('c3WebApp')
        .controller('CertificateRequestBatchDetailController', CertificateRequestBatchDetailController);

    CertificateRequestBatchDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'CertificateRequestBatch',
                                                       'paginationConstants', 'pagingParams', 'ParseLinks', 'AlertService', 'RequestByBatch',
                                                       'BatchGenerateXml', 'Resend', 'BatchCancel', '$state', 'RequestPdf', 'RequestXml',
                                                       'RequestCancel'];

    function CertificateRequestBatchDetailController($scope, $rootScope, $stateParams, previousState, entity, CertificateRequestBatch,
    													paginationConstants, pagingParams, ParseLinks, AlertService, RequestByBatch,
    													BatchGenerateXml, Resend, BatchCancel, $state, RequestPdf, RequestXml,
    													RequestCancel) {
        var vm = this;

        vm.batch = entity;
        vm.previousState = 'certificate-request-batch';
        
        var unsubscribe = $rootScope.$on('c3WebApp:certificateRequestBatchUpdate', function(event, result) {
            vm.batch = result;
        });
        $scope.$on('$destroy', unsubscribe);
        
        // Load Requests
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;

        loadAll();

        function loadAll () {
        	RequestByBatch.query({
        		id: vm.batch.id,
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.requests = data;
                vm.page = pagingParams.page;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
            	id: vm.batch.id,
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }
        
        vm.cancel = function () {
        	BatchCancel.get({id:vm.batch.id}).$promise.then(function (result) {
        		$scope.$emit('c3WebApp:certificateRequestBatchUpdate', result);
        		window.location.href = '/#/'+vm.previousState;
        	});
        }

		vm.generateXmlAgain = function () {
			BatchGenerateXml.get({id:vm.batch.id}).$promise.then(function (result) {
				$scope.$emit('c3WebApp:certificateRequestBatchUpdate', result);
				window.location.href = '/#/'+vm.previousState;
        	});
		}

		vm.resendBatch = function () {
			Resend.get({id:vm.batch.id}).$promise.then(function (result) {
				$scope.$emit('c3WebApp:certificateRequestBatchUpdate', result);
				window.location.href = '/#/'+vm.previousState;
        	});
		}

		vm.downloadPdf = function (requestId) {
			RequestPdf.get({id:requestId}).$promise.then(function(result) {
	      	      var url = URL.createObjectURL(new Blob([result.data]));
	      	      var a = document.createElement('a');
	      	      a.href = url;
	      	      a.download = result.filename;
	      	      a.target = '_blank';
	      	      document.body.appendChild(a);
	      	      a.click();
	      	      document.body.removeChild(a);
      	    });
		}
		
		vm.downloadXml = function (requestId) {
			RequestXml.get({id:requestId}).$promise.then(function(result) {
	      	      var url = URL.createObjectURL(new Blob([result.data]));
	      	      var a = document.createElement('a');
	      	      a.href = url;
	      	      a.download = result.filename;
	      	      a.target = '_blank';
	      	      document.body.appendChild(a);
	      	      a.click();
	      	      document.body.removeChild(a);
    	    });
		}
		
		vm.cancelRequest = cancelRequest;
		
		function cancelRequest (request) {
			RequestCancel.get({id:request.id}).$promise.then(function (){
				request.cancelled = true;
			});
		}
    }
})();
