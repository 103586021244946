(function() {
    'use strict';

    angular
        .module('c3WebApp')
        .controller('NavbarController', NavbarController);

    NavbarController.$inject = ['$state', 'Auth', 'Principal', 'ProfileService', 'LoginService', 'SupervisorCount', 'SignRoleCount'];

    function NavbarController ($state, Auth, Principal, ProfileService, LoginService, SupervisorCount, SignRoleCount) {
        var vm = this;

        vm.isNavbarCollapsed = true;
        vm.isAuthenticated = Principal.isAuthenticated;

        ProfileService.getProfileInfo().then(function(response) {
            vm.inProduction = response.inProduction;
            vm.swaggerEnabled = response.swaggerEnabled;
        });

        vm.login = login;
        vm.logout = logout;
        vm.toggleNavbar = toggleNavbar;
        vm.collapseNavbar = collapseNavbar;
        vm.$state = $state;

        function login() {
            collapseNavbar();
            LoginService.open();
        }

        function logout() {
            collapseNavbar();
            Auth.logout();
            $state.go('home');
        }

        function toggleNavbar() {
            vm.isNavbarCollapsed = !vm.isNavbarCollapsed;
        }

        function collapseNavbar() {
            vm.isNavbarCollapsed = true;
        }
        
        vm.initCount = function () {
        	SupervisorCount.get().$promise.then(function (result) {
        		vm.supervisorTotal = result.data;
        	});
        	SignRoleCount.get().$promise.then(function (result) {
        		vm.signRoleTotal = result.data;
        	});
        }
        
    }
})();
