(function() {
    'use strict';
    angular
        .module('c3WebApp')
        .factory('TaskSignRole', TaskSignRole)
        .factory('TaskSignRoleConfig', TaskSignRoleConfig)
        .factory('FinalizeSignBatch', FinalizeSignBatch)
        .factory('OrderBatchToSign', OrderBatchToSign);

    TaskSignRole.$inject = ['$resource'];
    TaskSignRoleConfig.$inject = ['$resource'];
    FinalizeSignBatch.$inject = ['$resource'];
    OrderBatchToSign.$inject = ['$resource'];
    
    function TaskSignRole ($resource) {
        var resourceUrl =  'api/certificate-request-batches/:id/pendant/signrole';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }
    
    function TaskSignRoleConfig ($resource) {
        var resourceUrl =  'api/certificate-request-batches/signrole/config';

        return $resource(resourceUrl, {}, {
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            }
        });
    }
    
    function FinalizeSignBatch ($resource) {
        var resourceUrl =  'api/batch/finalize/sign/:id';

        return $resource(resourceUrl, {}, {
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            }
        });
    }

    function OrderBatchToSign ($resource) {
        var resourceUrl =  'api/certificate-request-batches/:id/sign/order';

        return $resource(resourceUrl, {}, {
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            }
        });
    }
    

})();
