(function() {
    'use strict';

    angular
        .module('c3WebApp')
        .controller('TaskSignRoleDetailController', TaskSignRoleDetailController);

    TaskSignRoleDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'TaskSignRole',
                                                       'paginationConstants', 'pagingParams', 'ParseLinks', 'AlertService', 'RequestByBatch',
                                                       'BatchCancel', 'BatchGenerateXml', 'BatchReject', 'BatchApprove',
                                                       'RequestPdf', 'RequestXml', '$state', 'config', 'FinalizeSignBatch',
                                                       'OrderBatchToSign'];

    function TaskSignRoleDetailController($scope, $rootScope, $stateParams, previousState, entity, TaskSignRole,
    													paginationConstants, pagingParams, ParseLinks, AlertService, RequestByBatch,
    													BatchCancel, BatchGenerateXml, BatchReject, BatchApprove,
    													RequestPdf, RequestXml, $state, config, FinalizeSignBatch,
    													OrderBatchToSign) {
        var vm = this;

        vm.useXades = config.useXades;
        vm.hostAddr = config.hostAddr;
        vm.policyUrl = config.policyUrl;
        vm.batch = entity;
        vm.previousState = 'task-sign-role';

        var unsubscribe = $rootScope.$on('c3WebApp:certificateRequestBatchUpdate', function(event, result) {
            vm.batch = result;
        });
        $scope.$on('$destroy', unsubscribe);

        // Load Requests
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;

        sdkDesktop.checkStarted();



        loadAll();

        function loadAll () {
        	RequestByBatch.query({
        		id: vm.batch.id,
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.requests = data;
                vm.page = pagingParams.page;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
            	id: vm.batch.id,
            	page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }

		vm.downloadPdf = function (requestId) {
			RequestPdf.get({id:requestId}).$promise.then(function(result) {
	      	      var url = URL.createObjectURL(new Blob([result.data]));
	      	      var a = document.createElement('a');
	      	      a.href = url;
	      	      a.download = result.filename;
	      	      a.target = '_blank';
	      	      document.body.appendChild(a);
	      	      a.click();
	      	      document.body.removeChild(a);
      	    });
		}

		vm.downloadXml = function (requestId) {
			RequestXml.get({id:requestId}).$promise.then(function(result) {
	      	      var url = URL.createObjectURL(new Blob([result.data]));
	      	      var a = document.createElement('a');
	      	      a.href = url;
	      	      a.download = result.filename;
	      	      a.target = '_blank';
	      	      document.body.appendChild(a);
	      	      a.click();
	      	      document.body.removeChild(a);
    	    });
		}

		vm.sign = function () {
			vm.isSign = true;

			if(vm.batch.totalToSign == 0){
				finalizeProcess();

			} else {
				// Order requests and start to sign
				OrderBatchToSign.get({id:vm.batch.id}).$promise.then(function (result) {
					sdkDesktop.markSignAndSendAllDocuments(
							function () {
								finalizeProcess();
							},
							function () {
								vm.isEnding = false;
								vm.isSign = false;
							}
					);
				}, function (reason) {
                    if (reason.status == 410) {
                        finalizeProcess();
                    }
				});

			}

        }

		function finalizeProcess () {
			vm.isEnding = true;
			vm.isSign = false;
			console.log("Finalizando assinatura");
			FinalizeSignBatch.get({id:vm.batch.id}).$promise.then(function () {
				vm.isEnding = false;
				window.location.href = '/#/'+vm.previousState;
			})
		}

		vm.config = function () {
        	sdkDesktop.showConfig();
        }

		// SDK parameters

		var parametersXades = {
				"generateLog" : "true",
				"messagesURL" : vm.hostAddr+"/signapplet/sdk-web_pt_BR.properties",
				"config.type" : "local",
				"xmlDSigPrefix" : "ds",
				"xmlDSigMultipleSigners" : "true",
				"jspServer" : vm.hostAddr+"api/batch/"+vm.batch.id+"/upload/request/",
				"colName.0" : "ID",
				"colAlias.0" : "$ID",
				"encodedFileParam" : "ENCDATA",
				"encodedFileCount" : "QTYDATA",
				"encodedFileId" : "IDDATA",
				"colCount" : "1",
				"numberOfFilesToSign" : ""+vm.batch.totalToSign,
				"url." : vm.hostAddr+"api/batch/"+vm.batch.id+"/download/request/",
				"detachedSignature" : "false",
				"signingAlgorithm" : "SHA256withRSA",
				"usePolicy" : "true",
				"envelopeType" : "xades-enveloped",
				"showCertDialog" : "false",
				"digestAlgorithm" : "SHA-256",
				"cookie" : "JSESSIONID=#{session.getId()}", // FIXME
				"policyURL" : ""+vm.policyUrl,
				"policyURLInSignature" : ""+vm.policyUrl
		}

		var parametersXmlDSig = {
				"generateLog" : "true",
				"messagesURL" : vm.hostAddr+"signapplet/sdk-web_pt_BR.properties",
				"config.type" : "local",
				"xmlDSigPrefix" : "ds",
				"xmlDSigMultipleSigners" : "true",
				"jspServer" : vm.hostAddr+"api/batch/"+vm.batch.id+"/upload/request/",
				"colName.0" : "ID",
				"colAlias.0" : "$ID",
				"encodedFileParam" : "ENCDATA",
				"encodedFileCount" : "QTYDATA",
				"encodedFileId" : "IDDATA",
				"colCount" : "1",
				"numberOfFilesToSign" : ""+vm.batch.totalToSign,
    			"url." : vm.hostAddr+"api/batch/"+vm.batch.id+"/download/request/",
				"detachedSignature" : "false",
				"signingAlgorithm" : "SHA256withRSA",
				"usePolicy" : "false",
				"envelopeType" : "xml-enveloped",
				"showCertDialog" : "false",
				"digestAlgorithm" : "SHA-256",
				"cookie" : "JSESSIONID=#{session.getId()}",
				"downloadMode" : "multiThread"
			}

        if(vm.useXades){
			sdkDesktop.setParameters(parametersXades);
		} else {
			sdkDesktop.setParameters(parametersXmlDSig);
		}

    }
})();
