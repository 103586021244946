(function() {
    'use strict';

    angular
        .module('c3WebApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('sign-role', {
            parent: 'entity',
            url: '/sign-role?page&sort&search',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'c3WebApp.signRole.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/sign-role/sign-roles.html',
                    controller: 'SignRoleController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,asc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('signRole');
                    $translatePartialLoader.addPart('user-management');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('sign-role-detail', {
            parent: 'sign-role',
            url: '/sign-role/{id}',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'c3WebApp.signRole.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/sign-role/sign-role-detail.html',
                    controller: 'SignRoleDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('signRole');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'SignRole', function($stateParams, SignRole) {
                    return SignRole.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'sign-role',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('sign-role-detail.edit', {
        	parent: 'sign-role-detail',
            url: '/detail/edit',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'c3WebApp.signRole.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/sign-role/sign-role-dialog.html',
                    controller: 'SignRoleDialogController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('signRole');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'SignRole', function($stateParams, SignRole) {
                    return SignRole.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'sign-role',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('sign-role.new', {
            parent: 'sign-role',
            url: '/new',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'c3WebApp.signRole.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/sign-role/sign-role-dialog.html',
                    controller: 'SignRoleDialogController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('signRole');
                    $translatePartialLoader.addPart('user-management');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'SignRole', function($stateParams, SignRole) {
                	return {
                        id: null
                    };
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'sign-role',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('sign-role.edit', {
            parent: 'sign-role',
            url: '/{id}/edit',

            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'c3WebApp.signRole.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/sign-role/sign-role-dialog.html',
                    controller: 'SignRoleDialogController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('signRole');
                    $translatePartialLoader.addPart('user-management');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'SignRole', function($stateParams, SignRole) {
                        return SignRole.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'sign-role',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('sign-role.delete', {
            parent: 'sign-role',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/sign-role/sign-role-delete-dialog.html',
                    controller: 'SignRoleDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['SignRole', function(SignRole) {
                            return SignRole.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('sign-role', null, { reload: 'sign-role' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
