(function() {
    'use strict';

    angular
        .module('c3WebApp')
        .controller('TemplateDialogController', TemplateDialogController);

    TemplateDialogController.$inject = ['$timeout', '$scope', '$stateParams', 'entity', 'Template', 'DataUtils', 
                                        'previousState', '$window', 'TemplateRequiredImage'];

    function TemplateDialogController ($timeout, $scope, $stateParams, entity, Template, DataUtils, 
    								previousState, $window, TemplateRequiredImage) {
        var vm = this;

        vm.template = entity;
        vm.clear = clear;
        vm.save = save;
        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
        }

        function save () {
            vm.isSaving = true;
            if (vm.template.id !== null) {
                Template.update(vm.template, onSaveSuccess, onSaveError);
            } else {
                Template.save(vm.template, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('c3WebApp:templateUpdate', result);
            vm.isSaving = false;
            $window.location.href = '/#/'+previousState.name;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.setXml = function ($file, template) {
            if ($file && $file.$error === 'pattern') {
                return;
            }
            if ($file) {
                DataUtils.toBase64($file, function(base64Data) {
                    $scope.$apply(function() {
                    	template.xmlTemplate = base64Data;
                    	template.xmlContentType = $file.type;
                    	template.xmlTemplateName = $file.name;
                    });
                });
            }
        };
        
        vm.setPdf = function ($file, template) {
            if ($file && $file.$error === 'pattern') {
                return;
            }
            if ($file) {
                DataUtils.toBase64($file, function(base64Data) {
                    $scope.$apply(function() {
                    	template.pdfTemplate = base64Data;
                    	template.pdfContentType = $file.type;
                    	template.pdfTemplateName = $file.name;
                    	getRequiredImages();
                    });
                });
            }
        };
        
        vm.setImage = function ($file) {
            if ($file && $file.$error === 'pattern') {
                return;
            }
            if ($file) {
                DataUtils.toBase64($file, function(base64Data) {
                    $scope.$apply(function() {
                    	var pdfImage = {
                    		image: base64Data ,
                    		name: $file.name 
                    	};
                    	if(!vm.template.pdfImages){
                    		vm.template.pdfImages = [];
                    	}
                    	vm.template.pdfImages.push(pdfImage);
                    });
                });
            }
        };
        
        vm.setImages = function ($files) {
        	angular.forEach($files, function(file) {
        		vm.setImage(file);
        	});
        }
        
        
        vm.removeImage = function (image) {
        	var index = vm.template.pdfImages.indexOf(image);
        	vm.template.pdfImages.splice(index, 1); 
        }
        
        function getRequiredImages () {
        	TemplateRequiredImage.get(vm.template).$promise.then(function (result) {
        		vm.requiredImages = result;
        	});
        }
    }
})();
