(function() {
    'use strict';

    angular
        .module('c3WebApp')
        .controller('AccessTokenDeleteController',AccessTokenDeleteController);

    AccessTokenDeleteController.$inject = ['$uibModalInstance', 'entity', 'AccessToken'];

    function AccessTokenDeleteController($uibModalInstance, entity, AccessToken) {
        var vm = this;

        vm.accessToken = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            AccessToken.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
