(function() {
    'use strict';

    angular
        .module('c3WebApp')
        .controller('SignRoleDialogController', SignRoleDialogController);

    SignRoleDialogController.$inject = ['$timeout', '$scope', '$stateParams', 'entity', 'SignRole', 'BrazilianCitizenByCpf', '$window', 'previousState'];

    function SignRoleDialogController ($timeout, $scope, $stateParams, entity, SignRole, BrazilianCitizenByCpf, $window, previousState) {
        var vm = this;

        vm.signRole = entity;
        if(vm.signRole && vm.signRole.person){
        	vm.wantAddPerson = true;
        	vm.cpf = vm.signRole.person.cpf;
        }
        vm.clear = clear;
        vm.save = save;
        vm.searchBrazilianCitizen = searchBrazilianCitizen;
        vm.clearPerson = clearPerson;
        
        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
        }

        function save () {
            vm.isSaving = true;
            if (vm.signRole.id !== null) {
                SignRole.update(vm.signRole, onSaveSuccess, onSaveError);
            } else {
                SignRole.save(vm.signRole, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('c3WebApp:signRoleUpdate', result);
            vm.isSaving = false;
            $window.location.href = '/#/'+previousState.name;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        function searchBrazilianCitizen (cpf) {
        	vm.isSaving = true;
        	BrazilianCitizenByCpf.get({cpf:cpf}, onSuccess, onError);
        }
        
        function onSuccess (result) {
        	if(result){
    			vm.signRole.person = result;
    		} else {
    			vm.signRole.person = {};
    		}
        	vm.isSaving = false;
        }
        
        function onError () {
        	vm.isSaving = false;
        }
        
        function clearPerson (wantToAddPerson) {
        	if(!wantToAddPerson){
        		vm.signRole.person = undefined;
        	}
        }
    }
})();
