(function() {
    'use strict';
    angular
        .module('c3WebApp')
        .factory('BrazilianCitizen', BrazilianCitizen)
        .factory('BrazilianCitizenByCpf', BrazilianCitizenByCpf);

    BrazilianCitizen.$inject = ['$resource'];
    BrazilianCitizenByCpf.$inject = ['$resource'];

    function BrazilianCitizen ($resource) {
        var resourceUrl =  'api/brazilian-citizens/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }
    
    function BrazilianCitizenByCpf ($resource) {
        var resourceUrl =  'api/brazilian-citizens/cpf/:cpf';

        return $resource(resourceUrl, {}, {
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            }
        });
    }
})();
