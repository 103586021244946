(function() {
    'use strict';
    angular
        .module('c3WebApp')
        .factory('CertificateRequestBatch', CertificateRequestBatch)
        .factory('BatchCancel', BatchCancel)
        .factory('BatchGenerateXml', BatchGenerateXml)
        .factory('Resend', Resend)
        .factory('BatchApprove', BatchApprove)
        .factory('BatchReject', BatchReject);

    CertificateRequestBatch.$inject = ['$resource'];
    BatchCancel.$inject = ['$resource'];
    BatchGenerateXml.$inject = ['$resource'];
    Resend.$inject = ['$resource'];
    BatchApprove.$inject = ['$resource'];
    BatchReject.$inject = ['$resource'];
    
    function CertificateRequestBatch ($resource) {
        var resourceUrl =  'api/certificate-request-batches/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }
    
    function BatchCancel ($resource) {
        var resourceUrl =  'api/certificate-request-batches/:id/cancel';

        return $resource(resourceUrl, {}, {
            'get': { method:'GET' }
        });
    }
    
    function BatchGenerateXml ($resource) {
        var resourceUrl =  'api/certificate-request-batches/:id/generateXml';

        return $resource(resourceUrl, {}, {
            'get': { method:'GET' }
        });
    }

    function Resend ($resource) {
        var resourceUrl =  'api/certificate-request-batches/:id/resend';

        return $resource(resourceUrl, {}, {
            'get': { method:'GET' }
        });
    }

    function BatchApprove ($resource) {
        var resourceUrl =  'api/certificate-request-batches/:id/approve';

        return $resource(resourceUrl, {}, {
            'get': { method:'GET' }
        });
    }
    
    function BatchReject ($resource) {
        var resourceUrl =  'api/certificate-request-batches/:id/reject';

        return $resource(resourceUrl, {}, {
            'get': { method:'GET' }
        });
    }
    
})();
