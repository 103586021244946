(function() {
    'use strict';

    angular
        .module('c3WebApp')
        .controller('SignRoleDetailController', SignRoleDetailController);

    SignRoleDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'SignRole'];

    function SignRoleDetailController($scope, $rootScope, $stateParams, previousState, entity, SignRole) {
        var vm = this;

        vm.signRole = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('c3WebApp:signRoleUpdate', function(event, result) {
            vm.signRole = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
