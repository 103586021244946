(function() {
    'use strict';

    angular
        .module('c3WebApp')
        .controller('BrazilianCitizenDialogController', BrazilianCitizenDialogController);

    BrazilianCitizenDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'BrazilianCitizen'];

    function BrazilianCitizenDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, BrazilianCitizen) {
        var vm = this;

        vm.brazilianCitizen = entity;
        vm.clear = clear;
        vm.save = save;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
        	
            vm.isSaving = true;
            if (vm.brazilianCitizen.id !== null) {
                BrazilianCitizen.update(vm.brazilianCitizen, onSaveSuccess, onSaveError);
            } else {
//            	vm.brazilianCitizen.authorities
                BrazilianCitizen.save(vm.brazilianCitizen, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('c3WebApp:brazilianCitizenUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


    }
})();
